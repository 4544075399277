import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby';
import services from '../data/services.json';
import services_list from '../data/services_list.json';
import { StaticImage } from "gatsby-plugin-image"
import Seo from '../components/seo';

export default function HowWeCanHelp() {
    const services_hero = '../assets/illustrations/stylish/website-designing-1768780-0.svg';

    return (
        <Layout>
            <Seo title="Services" />
            <div className="flex qapps-hero-card m-4 lg:m-16 lg:mt-10 2xl:mx-28 h-full relative z-30 overflow-visible relative">
                {/* <div className="qapps-hero-card m-4 lg:m-16 lg:mt-10 h-full relative z-30 overflow-visible relative"> */}
                {/* <div id="services_hero_image_sm" className="filter drop-shadow-xl w-full flex justify-center overflow-visible block sm:hidden md:hidden z-10">
                    <StaticImage
                        className="flex object-contain overflow-visible h-full w-8/12"
                        src={services_hero}
                        placeholder="tracedSVG"
                        alt=""
                    />
                </div> */}
                <div id="hero" className="flex z-30 pb-10 md:pb-20">
                    {/* <main className="mt-10 w-full md:w-7/12 lg:w-7/12 xl:w-7/12 3xl:w-11/12 px-6 sm:px-8 lg:px-16 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28"> */}
                    <main className="mt-10 w-full px-6 sm:px-8 lg:px-16 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
                        <div className="text-center md:text-left">
                            <div className="grid grid-cols-1 gap-y-4 divide-dark-tf divide-opacity-10">
                                <p className="head-font font-semibold text-dark-tf text-2.5xl lg:text-4xl">Scalable Platforms</p>
                                <p className="head-font font-semibold text-dark-tf text-2.5xl lg:text-4xl">Powerful Data Analytics Solutions</p>
                                <p className="head-font font-semibold text-dark-tf text-2.5xl lg:text-4xl">Productivity Applications</p>
                            </div>
                            <p className="mt-5 sm:mt-5 md:mt-10 lg:mt-15 pl-1 font-normal text-base text-notsodark-tf text-lg md:text-xl xl:text-2xl 4xl:text-3xl lg:mx-0 leading-relaxed md:leading-loose xl:leading-relaxed">
                                We help companies and organizations create enterprise software and applications that leverages the power of data analytics to help you make better decisions at scale.
                            </p>
                        </div>
                        {/* <div className="mt-8 flex lg:mt-10 lg:flex-shrink-0 w-full justify-center md:justify-start">
                            <div className="inline-flex rounded-md shadow">
                                <Link to="#services_features" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg md:text-xl 4xl:text-3xl font-medium rounded-md text-gray-50 bg-blue-tf hover:bg-dark-tf">
                                    Let's get started
                                </Link>
                            </div>
                        </div> */}
                    </main>
                </div>
                {/* <div id="services_hero_image" className="filter drop-shadow-xl flex justify-end overflow-visible absolute top-20 xxs:top-20 sm:top-0 md:top-28 lg:top-28 2xl:-top-10 right-0 lg:right-5 xl:right-0 w-7/12 sm:w-5/12 md:w-6/12 lg:w-5/12 hidden md:block z-10 opacity-50 sm:opacity-100"> */}
                <div id="services_hero_image" className="filter drop-shadow-xl flex self-center justify-center overflow-visible right-0 w-full 2.5xl:w-3/4 3xl:w-1/2 4xl:w-5/12 hidden md:block z-10 opacity-50 xs:opacity-100">
                    <StaticImage
                        className="flex object-scale-down overflow-visible"
                        src={services_hero}
                        placeholder="tracedSVG"
                        quality={100}
                        alt=""
                    />
                </div>
            </div>

            <div id="services_hero_image_sm" className="filter drop-shadow-xl w-full flex justify-center overflow-visible block md:hidden z-10">
                <StaticImage
                    className="flex object-contain overflow-visible h-full w-8/12"
                    src={services_hero}
                    placeholder="tracedSVG"
                    alt=""
                />
            </div>

            <div id="services_features" className="bg-white py-8 mt-10">
                <div className="flex flex-col items-center m-4 lg:m-16">
                    <div className="w-3/4 md:w-5/7">
                        <h4 className="text-3xl 4xl:text-4xl font-bold text-dark-tf text-center">Do you have these problems?</h4>
                    </div>
                    <div className="flex-none lg:flex">
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-1 xl:grid-cols-3 gap-8 flex justify-between ">
                            {services.map((item) => (
                                <div key={item.title} className="border-2 border-qapps-blue p-4 rounded-3xl">
                                    <h2 className="head-font font-medium text-xl md:text-lg lg:text-xl 4xl:text-3xl pt-3 text-center text-darkblue-tf ">{item.title}</h2>
                                    <p className="mt-3 text-xl 4xl:text-2xl text-gray-500 text-center px-10" dangerouslySetInnerHTML={{ __html: item.description }} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="mt-16 ">
                        <h4 className="text-4xl 4xl:text-5xl font-medium text-dark-tf text-center"><strong>Timefree</strong> is your partner in creating powerful applications designed to solve these.</h4>
                    </div>
                </div>
            </div>

            <div id="services_stack" className="py-8 mt-5">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 items-start m-4 lg:mx-16 gap-x-8">
                    <div className="col-span-1 md:col-span-2 px-4">
                        <h4 className="uppercase text-2.5xl 4xl:text-3xl head-font font-medium text-darkblue-tf text-center md:text-left pt-6">Why work with us?</h4>
                        <p className="text-gray-600 text-center md:text-left text-xl 4xl:text-2xl mt-4 leading-relaxed">We specialize in Microsoft Cloud Technologies, specifically in implementing scalable solutions and applications in Microsoft Azure that lowers operational cost, enhances scalability, and improves usability and availability across a wide range of devices from desktop to mobile devices.</p>
                        {/* <div className="mt-6 flex lg:flex-shrink-0 w-full justify-center md:justify-start">
                            <div className="inline-flex rounded-md shadow">
                                <Link to="#howitworks" className="inline-flex items-center justify-center px-5 py-3 border-2 border-darkblue-tf text-l md:text-xl font-medium rounded-md text-darkblue-tf hover:bg-darkblue-tf hover:text-gray-50">
                                    How it works
                                </Link>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-span-1 md:col-span-3 mt-6 lg:mt-0 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8 md:gap-y-16 flex justify-between items-stretch">
                        {
                            services_list.map((item) => (
                                <div key={item.title}>
                                    <div className="text-center md:text-left bg-qapps-blue p-10 rounded-2xl self-stretch h-full">
                                        <div className="flex justify-center md:justify-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-darkblue-tf" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d={item.svg} />
                                            </svg>
                                        </div>
                                        <div className="mt-6">
                                            <h3 className="head-font font-semibold text-lg 4xl:text-2xl text-darkblue-tf">{item.title}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div id="services_cta" className="bg-white">
                <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center md:text-left">
                        <span className="block text-gray-700 mr-2">How can we help? <span className="block text-darkblue-tf ">Let's talk.</span></span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 justify-center md:justify-start">
                        <div className="inline-flex rounded-md shadow-xl shadow-darkblue-tf/20">
                            <Link to="/contact" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg md:text-xl 4xl:text-3xl font-medium rounded-md text-gray-50 bg-darkblue-tf  hover:bg-blue-tf">
                                Contact us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
